<template>
  <div id="invite" v-loading="loading">
    <div class="cm-content">
      <h-invite :params="params" @change="handleSearch"></h-invite>
      <br />
      <el-table :data="data">
        <el-table-column label="编号" prop="pk"> </el-table-column>
        <el-table-column label="邀请码" prop="code"></el-table-column>
        <el-table-column label="用户昵称" prop="user_nickname">
        </el-table-column>
        <el-table-column label="用户手机" prop="user_cellphone">
        </el-table-column>
        <el-table-column label="租户名称" prop="tenant_name"> </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatInviteCodeState }}
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleChangeState(scope.row.pk, { state: 1 })"
            >
              启用
            </el-button>
            <el-button
              size="mini"
              type="warning"
              @click="handleChangeState(scope.row.pk, { state: -1 })"
            >
              禁用
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import HInvite from "@/components/headers/HInvite";

import invite from "@/api/invite";

export default {
  name: "invite",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HInvite,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
      if (route.query.keyword) {
        this.params["keyword"] = route.query.keyword;
      }
    },
    handleList(params) {
      this.loading = true;
      invite.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleChangeState(pk, data) {
      this.$confirm("是否修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          invite
            .changeState(pk, data)
            .then(() => {
              this.handleList(this.params);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "invite",
          query: params,
        })
        .catch(() => {});
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.params = val;
      this.$router
        .push({
          name: "invite",
          query: val,
        })
        .catch(() => {});
    },
    handleDialogChange(val) {
      if (val) {
        this.handleList(this.params);
      } else {
        this.handleList();
      }
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "invite") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#invite {
  display: flex;
  height: 100%;
}
</style>

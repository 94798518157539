import api from "@/api";

const invite = {
  list(params) {
    return api.get(`/invite-code`, {
      params: params,
    });
  },
  changeState(pk, data) {
    return api.patch(`/invite-code/${pk}/state`, data);
  },
};

export default invite;
